<template>
  <div>
    <el-image
      fit="fill"
      style="width: 100%; height:290px"
      :src="bannerUrl"
    ></el-image>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <li class="tabStyleLi">
            <div class="tabStyle" style="background:#3246F4"></div>
            <div
              class="tabStyle2"
              style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
            >
              <div>01</div>
              <div>全面IDC服务</div>
            </div>
          </li>
          <li class="tabStyleLi">
            <div class="tabStyle"></div>
            <div
              style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
            >
              <div>02</div>
              <div>全球ITC服务能力</div>
            </div>
          </li>
          <li class="tabStyleLi">
            <div class="tabStyle"></div>
            <div
              style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
            >
              <div>03</div>
              <div>案例分享</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">首页</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/solutions/index' }"
          ><span style="color:#303133"
            >面向全球ICT服务能力</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">全面IDC服务</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="width:1200px; margin:0 auto; height:500px">
      <div style="float:left;width:500px; margin-top:150px;">
        <div
          style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
        >
          机房基础建设
        </div>
        <div style="text-indent:30px; margin-top:30px;text-align:left; ">
          国力科技是从事软件及信息产品开发、系统集成和信息服务的民营高科技企业。国力科技主营业务包括：软件开发、系统集成及技术服务，旨在为用户提供全面的IT解决方案。
        </div>
        <div style="margin-top:30px">
          <el-button round>进一步了解</el-button>
        </div>
      </div>
      <div style="float:right;margin-top:50px;">
        <el-image
          :src="corporateVision1"
          style="width:640px; height:400px;"
        ></el-image>
      </div>
    </div>
    <div style="width:1200px; margin:0 auto; height:500px">
      <div style="float:left;margin-top:50px;">
        <el-image
          :src="corporateVision1"
          style="width:640px; height:400px;"
        ></el-image>
      </div>
      <div style="float:right;width:500px; margin-top:150px;">
        <div
          style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
        >
          综合布线/弱电/动环
        </div>
        <div style="text-indent:30px; margin-top:30px;text-align:left; ">
          国力科技是从事软件及信息产品开发、系统集成和信息服务的民营高科技企业。国力科技主营业务包括：软件开发、系统集成及技术服务，旨在为用户提供全面的IT解决方案。
        </div>
        <div style="margin-top:30px">
          <el-button round>进一步了解</el-button>
        </div>
      </div>
    </div>
    <div style="width:1200px; margin:0 auto; height:500px">
      <div style="float:left;width:500px; margin-top:150px;">
        <div
          style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
        >
          设备安装部署与系统集成
        </div>
        <div style="text-indent:30px; margin-top:30px;text-align:left; ">
          国力科技是从事软件及信息产品开发、系统集成和信息服务的民营高科技企业。国力科技主营业务包括：软件开发、系统集成及技术服务，旨在为用户提供全面的IT解决方案。
        </div>
        <div style="margin-top:30px">
          <el-button round>进一步了解</el-button>
        </div>
      </div>
      <div style="float:right;margin-top:50px;">
        <el-image
          :src="corporateVision1"
          style="width:640px; height:400px;"
        ></el-image>
      </div>
    </div>
    <el-pagination
      style="margin-top:30px;margin-bottom:30px"
      background
      layout="prev, pager, next"
      :total="1000"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/aboutus/top-banner.png'),
      corporateVision1: require('../../../assets/aboutus/corporateVision-img1.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
